<template>
  <el-table
    :data="isLoading ? null : featureFlags"
    border
    class="w-full rounded"
    :row-key="rowKeyer"
    :expand-row-keys="expandedRows"
    @sort-change="sortColumns"
    @header-click="headerClick"
    @expand-change="expandChange"
  >
    <template v-slot:empty>
      <table-empty-slot
        :is-loading="isLoading"
        :has-data="!!featureFlags"
        empty-message="No Feature Flags found. Try modifying your search criteria."
        error-message="There was a problem loading the Feature Flags. Please try again later."
      />
    </template>
    <el-table-column type="expand">
      <template v-slot="{ row }">
        <feature-flag-expandable-column
          :is-loading="isMutating"
          :feature="row"
          @toggle-entity="$emit('toggle-entity', $event)"
        />
      </template>
    </el-table-column>
    <el-table-column sortable="custom" prop="name" label="Name">
      <template v-slot="{ row }">
        {{ row.name }}
      </template>
    </el-table-column>
    <el-table-column
      sortable="custom"
      prop="is_enabled"
      label="Enabled Globally"
    >
      <template v-slot="{ row }">
        <feature-flag-status-column
          :feature="row"
          @toggle-global="$emit('toggle-global', $event)"
        />
      </template>
    </el-table-column>
    <el-table-column sortable="custom" prop="name" label="Created At">
      <template v-slot="{ row }">
        {{ row.created_at }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import FeatureFlagStatusColumn from './FeatureFlagStatusColumn';
import FeatureFlagExpandableColumn from './FeatureFlagExpandableColumn';

import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';

export default {
  components: {
    FeatureFlagStatusColumn,
    FeatureFlagExpandableColumn,
    TableEmptySlot
  },
  props: {
    value: { type: Object, default: null },
    isLoading: Boolean,
    isMutating: Boolean,
    featureFlags: { type: Array, default: null },
    sort: { type: Object, default: null }
  },
  data() {
    return {
      expandedRows: []
    };
  },
  methods: {
    expandChange(row, expandedRows) {
      this.expandedRows = expandedRows.map(row => row.id);
    },
    headerClick(col) {
      if (!col.order) {
        this.$delete(this.sort, col.property);
        this.$emit('update:sort', { created_at: 'desc' });
      }
    },
    rowKeyer(row) {
      return row.id;
    },
    sortColumns(col) {
      if (col.prop && col.order) {
        this.$emit('update:sort', { [col.prop]: col.order });
      }
    }
  }
};
</script>

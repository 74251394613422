<template>
  <div class="flex">
    <custom-el-switch
      v-model="enabled"
      right
      active-text="True"
      inactive-text="False"
      green-red
      @change="onToggleGlobally"
    />
  </div>
</template>

<script>
import CustomElSwitch from '@/components/Custom/CustomElSwitch';

export default {
  components: {
    CustomElSwitch
  },
  props: {
    feature: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      enabled: this.feature ? this.feature.is_enabled : false
    };
  },
  watch: {
    feature(newValues) {
      if (newValues) {
        this.enabled = newValues.is_enabled;
      }
    }
  },
  methods: {
    onToggleGlobally(enabled) {
      this.$emit('toggle-global', { id: this.feature.id, enabled });
    }
  }
};
</script>

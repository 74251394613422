<template>
  <div class="flex flex-col items-start">
    <el-alert
      v-if="isEnabledGlobally"
      title=""
      class="mb-4 text-center w-1/2"
      type="warning"
      :closable="false"
    >
      You can only manage Account Specific features if the Feature is
      <b>Disabled</b> globally.
    </el-alert>

    <h3>
      Enable for a new Account
    </h3>
    <div class="flex mt-3 flex-row">
      <custom-el-select
        v-model="featurableInput.type"
        :is-disabled="isLoading || isEnabledGlobally"
        class="flex"
        singular
        placeholder="Select an Account Type"
        :options="FeaturablesInfo"
        hide-icons
      />
      <div class="flex ml-2">
        <el-input
          v-model="featurableInput.id"
          v-mask.integer
          :disabled="isLoading || isEnabledGlobally"
          placeholder="Entity ID"
        />
      </div>
      <el-button
        :disabled="isLoading || isEnabledGlobally"
        class="button-blue ml-2"
        @click="onEnableEntity"
      >
        Enable
      </el-button>
    </div>
    <template
      v-if="feature && feature.featurables && feature.featurables.length"
    >
      <h3 class="mt-6 mb-3">
        Currently enabled for these Accounts:
      </h3>
      <div
        v-for="featurable in feature.featurables"
        :key="`feature-${feature.id}-featurable-${featurable.id}`"
        class="py-3 border-top flex min-w-30 justify-between"
      >
        <template v-if="!isLoading">
          <div class="flex">
            {{ FeaturableLabels[featurable.featurable_type] }}
            ({{ featurable.featurable_id }})
          </div>
          <el-button
            :disabled="isEnabledGlobally"
            class="ml-4 button-red p-1"
            @click="onDisableEntity(featurable)"
          >
            Remove / Disable
          </el-button>
        </template>
        <loader-box v-else height="2.25em" class="w-full" />
      </div>
    </template>
  </div>
</template>

<script>
import CustomElSelect from '@/components/Custom/CustomElSelect';

import { FeaturableLabels, FeaturablesInfo } from '@/constants';
import { integer } from '@/utils/filters';

export default {
  components: {
    CustomElSelect
  },
  props: {
    isLoading: Boolean,
    feature: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      FeaturablesInfo,
      FeaturableLabels,
      featurableInput: {
        id: null,
        type: null
      }
    };
  },
  computed: {
    isEnabledGlobally() {
      return this.feature && this.feature.is_enabled;
    }
  },
  methods: {
    onDisableEntity(featurable) {
      this.$emit('toggle-entity', {
        id: this.feature.id,
        input: {
          id: featurable.featurable_id,
          model: featurable.featurable_type,
          enabled: false
        }
      });
    },
    onEnableEntity() {
      this.$emit('toggle-entity', {
        id: this.feature.id,
        input: {
          id: integer(this.featurableInput.id),
          model: this.featurableInput.type,
          enabled: true
        }
      });
    }
  }
};
</script>

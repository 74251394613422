var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-content"},[_c('the-page-header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Feature Flags ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" Create and manage feature flags by User, Publisher, Buyer, or Globally ")]},proxy:true}])}),_c('feature-flags-query',{attrs:{"pager":_vm.pager,"filter":_vm.filter,"sort":_vm.sort},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var data = ref.data;
return [_c('feature-flag-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var toggleFeatureFlag = ref.toggleFeatureFlag;
var toggleFeaturableEntity = ref.toggleFeaturableEntity;
return [_c('el-input',{staticClass:"w-1/4 mb-3",attrs:{"placeholder":"Search by name","prefix-icon":"el-icon-search","debounce":500},on:{"input":_vm.onSearchText},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('feature-flag-table',{attrs:{"is-loading":isLoading,"is-mutating":loading,"sort":_vm.sort,"feature-flags":isLoading ? null : data.data},on:{"update:sort":function($event){_vm.sort=$event},"toggle-global":function($event){return toggleFeatureFlag($event.id, $event.enabled)},"toggle-entity":function($event){return toggleFeaturableEntity($event.id, $event.input)}}}),_c('pagination',{staticClass:"mt-6",attrs:{"info":data ? data.paginatorInfo : {},"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}})]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
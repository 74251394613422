<template>
  <section class="page-content">
    <the-page-header>
      <template #title>
        Feature Flags
      </template>
      <template #subtitle>
        Create and manage feature flags by User, Publisher, Buyer, or Globally
      </template>
    </the-page-header>

    <feature-flags-query :pager="pager" :filter="filter" :sort="sort">
      <template v-slot="{ isLoading, data }">
        <feature-flag-mutation>
          <template
            v-slot="{ loading, toggleFeatureFlag, toggleFeaturableEntity }"
          >
            <el-input
              v-model="searchText"
              class="w-1/4 mb-3"
              placeholder="Search by name"
              prefix-icon="el-icon-search"
              :debounce="500"
              @input="onSearchText"
            />

            <feature-flag-table
              :is-loading="isLoading"
              :is-mutating="loading"
              :sort.sync="sort"
              :feature-flags="isLoading ? null : data.data"
              @toggle-global="toggleFeatureFlag($event.id, $event.enabled)"
              @toggle-entity="toggleFeaturableEntity($event.id, $event.input)"
            />

            <pagination
              class="mt-6"
              :info="data ? data.paginatorInfo : {}"
              :pager.sync="pager"
            />
          </template>
        </feature-flag-mutation>
      </template>
    </feature-flags-query>
  </section>
</template>

<script>
import { FeatureFlagMutation } from '@/components/Mutations';
import FeatureFlagTable from './components/FeatureFlagTable';
import { FeatureFlagsQuery } from '@/components/Queries';
import Pagination from '@/components/Core/Table/Pagination';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

import { debounce } from 'lodash';

export default {
  components: {
    FeatureFlagMutation,
    FeatureFlagTable,
    FeatureFlagsQuery,
    Pagination,
    ThePageHeader
  },
  data() {
    return {
      filter: {},
      pager: { perPage: 10, page: 1 },
      searchText: null,
      sort: {
        created_at: 'desc'
      }
    };
  },
  methods: {
    onSearchText: debounce(function() {
      this.filter = { ...this.filter, name: { like: this.searchText } };
    }, 500)
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"w-full rounded",attrs:{"data":_vm.isLoading ? null : _vm.featureFlags,"border":"","row-key":_vm.rowKeyer,"expand-row-keys":_vm.expandedRows},on:{"sort-change":_vm.sortColumns,"header-click":_vm.headerClick,"expand-change":_vm.expandChange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.featureFlags,"empty-message":"No Feature Flags found. Try modifying your search criteria.","error-message":"There was a problem loading the Feature Flags. Please try again later."}})]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('feature-flag-expandable-column',{attrs:{"is-loading":_vm.isMutating,"feature":row},on:{"toggle-entity":function($event){return _vm.$emit('toggle-entity', $event)}}})]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"is_enabled","label":"Enabled Globally"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('feature-flag-status-column',{attrs:{"feature":row},on:{"toggle-global":function($event){return _vm.$emit('toggle-global', $event)}}})]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"name","label":"Created At"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }